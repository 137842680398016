<template lang="pug">
  .section-content(:class="{'contains-dropdown': containsDropdown}")
    slot
</template>

<script>
  export default {
    props: {
      containsDropdown: Boolean
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .section-content
    align-items: center
    border-bottom: $element-border
    display: flex
    padding: 10px

  .contains-dropdown
    padding: 3px
</style>
